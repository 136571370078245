import { useQuery, useReactiveVar } from '@apollo/client';
import React, { Fragment, useCallback, useMemo } from 'react';
import { BusUserProfile } from '../../components/Profile/types';
import { ViewContainer } from '../../components/Shared/Shared';
import TabsHeader from '../../components/Shared/TabsHeader';
import useNavigateOnTab from '../../hooks/useNavigateOnTab';
import useSelectedState from '../../hooks/useSelectedState';
import { GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { isUserSuperAdmin, isUserSuperVisor, isUserSuperVisorOrAdminOrSuper, isUserSuperVisorOrAdminOrSuperOrSupport } from '../../utils/helpers';
import BranchBilling from './BranchBilling/BranchBilling';
import BranchFormModal from './BranchForms/BranchFormModal';
import BranchForms from './BranchForms/BranchForms';
import BranchMessageModal from './BranchMessages/BranchMessageModal';
import BranchMessages from './BranchMessages/BranchMessages';
import BranchPayouts from './BranchPayouts/BranchPayouts';
import BranchSummary from './BranchSummary/BranchSummary';
import BranchTags from './BranchTags/BranchTags';
import BranchTagsModal from './BranchTags/BranchTagsModal';
import BusUserModal from './BusUsers/BusUserModal';
import BusUsers from './BusUsers/BusUsers';
import { AddNewItem } from './Buttons';
import Discounts from './Discounts/Discounts';
import DiscountsModal from './Discounts/DiscountsModal';
import ImportProductsModal from './Products/ImportProductsModal';
import ProductModal from './Products/ProductModal';
import Products from './Products/Products';
import ServiceModal from './Services/ServiceModal';
import Services from './Services/Services';
import SubscriptionModal from './StoreSubscriptions/StoreSubscriptionModal';
import Subscriptions from './StoreSubscriptions/StoreSubscriptions';
import { MODAL_TABS_TYPES, TABS_TYPES } from './types';

const Store = () => {
  const [selected, setSelected] = useSelectedState({ defaultTab: TABS_TYPES.SERVICES, TABS_TYPES });
  const { data: { getBusUserProfile: busUserProfile } = {}, loading } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile);

  useNavigateOnTab({
    selected
  });
  const isAdmin = isUserSuperVisorOrAdminOrSuperOrSupport(busUserProfile) && !loading;
  const isSuperAdmin = isUserSuperAdmin(busUserProfile) && !loading;
  const isSuperVisor = isUserSuperVisor(busUserProfile) && !loading;

  const tabs = isSuperVisor
    ? []
    : ([
        { name: TABS_TYPES.SERVICES, component: () => <Services />, modals: [() => <ServiceModal />] },
        { name: TABS_TYPES.SUBSCRIPTIONS, component: () => <Subscriptions />, modals: [() => <SubscriptionModal />] },
        {
          name: TABS_TYPES.PRODUCTS,
          component: () => <Products />,
          modals: [
            () => <ProductModal />,
            {
              title: 'Import Products',
              component: () => <ImportProductsModal />
            }
          ]
        },
        { name: TABS_TYPES.AUTO_MESSAGES, component: () => <BranchMessages />, modals: [() => <BranchMessageModal />] },
        { name: TABS_TYPES.BRANCH_TAGS, component: () => <BranchTags />, modals: [() => <BranchTagsModal />] },
        { name: TABS_TYPES.REPORTS, component: () => <BranchSummary />, modals: [] },
        isAdmin && { name: TABS_TYPES.BILLING, component: () => <BranchBilling /> },
        { name: TABS_TYPES.FORMS, component: () => <BranchForms />, modals: [() => <BranchFormModal />] },
        { name: TABS_TYPES.DISCOUNTS, component: () => <Discounts />, modals: [() => <DiscountsModal />] },
        isSuperAdmin && { name: TABS_TYPES.BUS_USERS, component: () => <BusUsers />, modals: [() => <BusUserModal />] },
        isSuperAdmin && {
          name: TABS_TYPES.PAYOUTS,
          component: () => <BranchPayouts />
        }
      ].filter(Boolean) as { name: TABS_TYPES; component: () => JSX.Element; modals: { title: string; component: () => JSX.Element } | (() => JSX.Element)[] }[]);

  const tabsToRender = useMemo(() => {
    const allTabs = Object.values(TABS_TYPES);

    if (isSuperAdmin) {
      return allTabs;
    }

    if (isAdmin) {
      return allTabs.filter(tab => tab !== TABS_TYPES.BUS_USERS && tab !== TABS_TYPES.BILLING && tab !== TABS_TYPES.PAYOUTS);
    }

    return allTabs.filter(tab => tab !== TABS_TYPES.BUS_USERS && tab !== TABS_TYPES.BILLING && tab !== TABS_TYPES.PAYOUTS);
  }, [isAdmin, isSuperAdmin]);

  const Header = useCallback(() => {
    const productsHeaderActions = useReactiveVar(vars.productsHeaderActions);
    const actions = (
      <>
        {productsHeaderActions.map(({ id, action }) => (
          <Fragment key={id}>{action}</Fragment>
        ))}
        <AddNewItem type={selected as TABS_TYPES} tabs={tabs} />
      </>
    );

    return (
      <TabsHeader
        tabs={tabsToRender}
        modalTabs={Object.values(MODAL_TABS_TYPES)}
        actions={actions}
        selected={selected}
        setSelected={setSelected}
        actionsWidth={selected === TABS_TYPES.PRODUCTS ? 350 : 40}
      />
    );
  }, [selected, setSelected, JSON.stringify(tabs), JSON.stringify(tabsToRender)]);

  const SelectedTab = useMemo(() => tabs.find(tab => tab.name === selected)?.component, [selected, JSON.stringify(tabs)]);

  return (
    <ViewContainer>
      <Header />
      {SelectedTab && <SelectedTab />}
    </ViewContainer>
  );
};

export default Store;
