import { useMutation, useReactiveVar } from '@apollo/client';
import React, { Fragment, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Colors from '../../Colors';
import useBookingsListBulkActions from '../../hooks/useBookingsListBulkActions';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import useReduceAppointments from '../../hooks/useReduceAppointments';
import useSelectedState from '../../hooks/useSelectedState';
import { GetBillingListBranchAppointments, GetBranchOrderSubscriptions, GetBranchOrders, RetryOrderSubscriptionRepeatInvoice, UpdateOrderSubscriptionRepeatInvoiceStatus } from '../../queries';
import { vars } from '../../reactive';
import { addDrawerBar, unsetDrawerBar } from '../../reactive/actions';
import { toDateInput } from '../../utils/dates';
import { BookingsBulkActionsRef } from '../../views/Bookings/BookingsList';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking, BookingOrder } from '../../views/Bookings/types';
import { getBookingTitles } from '../../views/Bookings/utils';
import { ORDER_STATUS, ProductOrder } from '../../views/Orders/types';
import { getOrderTitles } from '../../views/Orders/utils';
import { BranchAppUser, CurrentPeriod } from '../../views/Store/BranchBilling/types';
import { SUBSCRIPTION_STATUS_TYPES, Subscription } from '../../views/Subscriptions/types';
import { getSubscriptionRepeatTitles, getSubscriptionTitles } from '../../views/Subscriptions/utils';
import { RecordBody } from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import ModalDialog from '../Modal/ModalDialog';
import { FILTERS_ID } from '../Shared/Filters/config';
import { Icon } from '../Shared/Forms/Forms';
import { Checkbox, CheckboxItemContainer } from '../Shared/Forms/styled';
import InfiniteList from '../Shared/InfiniteList/InfiniteList';
import OptionDropdown, { OptionDropDownItem } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import RefundModal from '../Shared/Modals/RefundModal/RefundModal';
import { FlexRow } from '../Shared/Shared';
import TabsHeader from '../Shared/TabsHeader';
import {
  BillingStatus,
  BookingListItem,
  BookingsDivider,
  BookingsListCotainer,
  ListHeaderContainer,
  ListHeaderContentContainer,
  ListHeaderText,
  ListView,
  SubscriptionsInvoiceListContainer,
  UserBookingContainer,
  UserBookingsContainer
} from './styled';

enum UserOrdersListTabs {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL'
}
export enum UserSubscriptionsListTabs {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  INVOICES = 'INVOICES'
}
enum UserBookingsListTabs {
  ACTIVE_PERIOD = 'ACTIVE_PERIOD',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED'
}

const AppUserBookings = ({ userPetRecordsIds, period }: { userPetRecordsIds: string[]; period: CurrentPeriod }) => {
  const [currentTab, setCurrentTab] = useSelectedState<typeof UserBookingsListTabs>({ defaultTab: UserBookingsListTabs.ACTIVE_PERIOD, TABS_TYPES: UserBookingsListTabs });
  const active = currentTab === UserBookingsListTabs.ACTIVE_PERIOD;
  const confirmed = currentTab === UserBookingsListTabs.CONFIRMED;
  const [[hasMoreBookings, setHasMoreBookings], queryResult] = usePaginatedQuery<Booking[]>({
    query: GetBillingListBranchAppointments,
    defaultStatus: [BOOKING_STATUS_TYPES.CANCELED, BOOKING_STATUS_TYPES.CONFIRMED],
    limit: 50,
    otherVariables: {
      PetRecordId: userPetRecordsIds,
      timestamp_from: active ? period.start : null,
      timestamp_to: active ? period.end : null,
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      status: confirmed || active ? [BOOKING_STATUS_TYPES.CONFIRMED] : [BOOKING_STATUS_TYPES.CANCELED],
      requisite_queries: [],
      alternative_queries: [],
      order: 'ASC'
    },
    otherParams: {
      skip: !userPetRecordsIds.length
    }
  });
  const { data: { getBranchAppointments: appUserAppointments = [] } = {}, loading: loadingAppUserAppointments, fetchMore, refetch } = queryResult;

  const bookingsToRender = useReduceAppointments(appUserAppointments, {
    uniqueByOrderIdAndTimestamp: true,
    sortDesc: false
  });

  const addBookingDrawer = useCallback((booking: BookingOrder) => {
    unsetDrawerBar(DRAWER_IDS.SUBSCRIPTION_DRAWER);
    unsetDrawerBar(DRAWER_IDS.ORDER_DRAWER);
    addDrawerBar({ drawerId: DRAWER_IDS.BOOKING_DRAWER, recordData: booking, otherData: { refetch } });
  }, []);

  const bookingsListRef = useRef<BookingsBulkActionsRef>(null);

  const { bulkSelect } = useBookingsListBulkActions({
    bookingsToRender,
    ref: bookingsListRef,
    inDrawer: true,
    refetch
  });

  const { isSelected, show: showBulkActions, toggleSelectMultiple } = bulkSelect;

  const handleBookingClick = useCallback(
    (booking: BookingOrder) => {
      const appointmentsIds = booking.map(({ id }) => id);
      if (showBulkActions) {
        toggleSelectMultiple(appointmentsIds);
        return;
      }
      addBookingDrawer(booking);
    },
    [showBulkActions, toggleSelectMultiple, addBookingDrawer]
  );

  const headerActions = useReactiveVar(vars.bookingsHeaderActions);
  const filterHeaderActions = headerActions.filter(({ id }) => id !== FILTERS_ID);

  return (
    <ListView>
      <ListHeaderContainer>
        <TabsHeader tabs={Object.values(UserBookingsListTabs)} selected={currentTab} setSelected={setCurrentTab} backgroundColor={Colors.white} noPadding />
        <ListHeaderContentContainer>
          <ListHeaderText>{active ? `${toDateInput(period.start)} - ${toDateInput(period.end)}` : confirmed ? 'Confirmed Bookings' : 'Cancelled Bookings'}</ListHeaderText>
          <FlexRow>
            {filterHeaderActions.map(({ action, id }) => (
              <Fragment key={id}>{action}</Fragment>
            ))}
          </FlexRow>
        </ListHeaderContentContainer>
        <BookingsDivider />
      </ListHeaderContainer>
      <BookingsListCotainer>
        <InfiniteList
          hasMoreItems={hasMoreBookings}
          fetchMore={fetchMore}
          loading={loadingAppUserAppointments}
          setHasMoreItems={setHasMoreBookings}
          itemRenderer={(booking: BookingOrder) => {
            const { day, time, dateTo, orderItemName, orderNumber, priceString, multiDayProduct, isFree, isCanceled, isBilled, billedOnDate, petsNames, isPrepaidMarkedAsPaid } =
              getBookingTitles(booking);
            return (
              <BookingListItem key={booking[0].id}>
                <UserBookingContainer onClick={() => handleBookingClick(booking)} clickable>
                  {showBulkActions && (
                    <RecordBody width="50">
                      <CheckboxItemContainer checked={isSelected(booking[0].id)} noMargin>
                        <Checkbox>
                          <Icon viewBox="0 0 24 24">
                            <polyline points="20 6 9 17 4 12" />
                          </Icon>
                        </Checkbox>
                      </CheckboxItemContainer>
                    </RecordBody>
                  )}
                  <RecordBody width="80" fontWeight={'800'}>
                    {orderNumber}
                  </RecordBody>
                  <RecordBody width="150" fontWeight={'600'}>
                    {orderItemName}
                  </RecordBody>
                  <RecordBody width="150" fontWeight={'600'}>
                    {petsNames}
                  </RecordBody>
                  {!multiDayProduct && (
                    <RecordBody width="250" fontWeight="400">
                      <strong>
                        {day} {time}
                      </strong>
                    </RecordBody>
                  )}
                  {multiDayProduct && (
                    <RecordBody width="250">
                      <strong> {day}</strong> to <strong>{dateTo}</strong>
                    </RecordBody>
                  )}
                  <RecordBody width="auto" fontWeight="800">
                    {priceString}
                  </RecordBody>
                  <RecordBody width="150" fontWeight={'600'}>
                    {billedOnDate}
                  </RecordBody>
                  <RecordBody width="150" fontWeight="800" flexEnd noMargin>
                    <BillingStatus blue={isFree} gray={isCanceled} green={isBilled || isPrepaidMarkedAsPaid} red={!isFree && !isCanceled && !isBilled && !isPrepaidMarkedAsPaid}>
                      {isCanceled ? 'Cancelled' : isFree ? 'Free' : isBilled ? 'Paid' : isPrepaidMarkedAsPaid ? 'Marked Paid' : 'Unpaid'}
                    </BillingStatus>
                  </RecordBody>
                </UserBookingContainer>
                <BookingsDivider />
              </BookingListItem>
            );
          }}
          list={bookingsToRender}
          offset={appUserAppointments?.length}
        />
      </BookingsListCotainer>
    </ListView>
  );
};

const RepeatsListContainer = styled(RecordBody)`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;
const SubscriptionManageButton = styled.button`
  color: ${Colors.primary};
`;

const AppUserSubscriptions = ({ userPetRecordsIds }: { userPetRecordsIds: string[] }) => {
  const [updateInvoiceStatus] = useMutation(UpdateOrderSubscriptionRepeatInvoiceStatus);
  const [retryInvoice] = useMutation(RetryOrderSubscriptionRepeatInvoice);

  const userDrawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.USER_DRAWER);
  const otherData = userDrawerBar?.otherData || {};
  const defaultTab = otherData?.defaultSubTab || UserSubscriptionsListTabs.ACTIVE;
  const [currentTab, setCurrentTab] = useSelectedState<typeof UserSubscriptionsListTabs>({ defaultTab, TABS_TYPES: UserSubscriptionsListTabs });
  const active = currentTab === UserSubscriptionsListTabs.ACTIVE;
  const invoices = currentTab === UserSubscriptionsListTabs.INVOICES;
  const tabsStatus = active
    ? [SUBSCRIPTION_STATUS_TYPES.CONFIRMED]
    : invoices
    ? [SUBSCRIPTION_STATUS_TYPES.CONFIRMED, SUBSCRIPTION_STATUS_TYPES.CANCELED, SUBSCRIPTION_STATUS_TYPES.REQUESTED]
    : [SUBSCRIPTION_STATUS_TYPES.CANCELED];

  const [[hasMoreSubscriptions, setHasMoreSubscriptions], { data: { getBranchOrderSubscriptions: appUserSubscriptions = [] } = {}, loading: LoadingSubscriptions, fetchMore, refetch }] =
    usePaginatedQuery<Subscription[]>({
      query: GetBranchOrderSubscriptions,
      defaultStatus: tabsStatus,
      limit: 20,
      otherVariables: {
        PetRecordId: userPetRecordsIds,
        requisite_queries: [],
        alternative_queries: []
      },
      otherParams: {
        skip: !userPetRecordsIds.length
      }
    });

  const addSubscriptionDrawer = useCallback((subscription: Subscription) => {
    unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
    unsetDrawerBar(DRAWER_IDS.ORDER_DRAWER);
    addDrawerBar({ drawerId: DRAWER_IDS.SUBSCRIPTION_DRAWER, recordData: subscription, otherData: { refetch } });
  }, []);

  const renderRepeatsList = (subscription: Subscription) => {
    const { subscriptionNumber, subscriptionName } = getSubscriptionTitles({ subscription });
    const uniqueRepeatsByInvoiceId = (subscription?.OrderSubscriptionRepeats || []).reduce((acc, repeat) => {
      const { payment: { invoice_id: invoiceId = '' } = {} } = repeat;
      const invoice = acc.find(({ payment }) => payment?.invoice_id === invoiceId);
      if (!invoice) {
        acc.push(repeat);
      }
      return acc;
    }, [] as typeof subscription.OrderSubscriptionRepeats);
    return (
      <>
        <RepeatsListContainer fontWeight={'800'} fontSize={16}>
          ({subscriptionNumber} - {subscriptionName})<SubscriptionManageButton onClick={() => addSubscriptionDrawer(subscription)}>Manage</SubscriptionManageButton>
        </RepeatsListContainer>

        {uniqueRepeatsByInvoiceId?.map(repeat => {
          const isNotFree = repeat.total > 0;
          const { createdAt, isBlue, isGreen, isRed, statusTitle, repeatTotal, failReason, isBilled, isConfirmed, isMarkedAsPaid, isDraft, isUncollectible, isPartiallyRefunded } =
            getSubscriptionRepeatTitles(repeat);
          const voidInvoice = () => {
            updateInvoiceStatus({ variables: { id: repeat.id, invoice_status: 'void' } });
          };
          const markInvoiceUncollectible = () => {
            updateInvoiceStatus({ variables: { id: repeat.id, invoice_status: 'uncollectible' } });
          };
          const markInvoiceAsPaid = () => {
            updateInvoiceStatus({ variables: { id: repeat.id, invoice_status: 'mark_as_paid' } });
          };
          const retryInvoicePayment = () => {
            retryInvoice({ variables: { id: repeat.id } });
          };
          const refundRepeat = () => {
            ModalDialog.openModal({
              content: () => <RefundModal order={repeat.OrderItem.Order} repeat={repeat} shouldIssueRefund />,
              title: 'Refund Payment',
              onClose() {
                setTimeout(() => {
                  refetch();
                }, 3000);
              }
            });
          };
          const conditionalOptions =
            isBilled || isPartiallyRefunded
              ? [
                  isNotFree && {
                    name: 'Refund',
                    value: 'refund',
                    onClick: refundRepeat
                  }
                ]
              : isDraft || isUncollectible
              ? [
                  {
                    name: 'Mark As Paid',
                    value: 'mark_as_paid',
                    onClick: markInvoiceAsPaid
                  },
                  {
                    name: 'Retry Payment',
                    value: 'retry',
                    onClick: retryInvoicePayment
                  }
                ]
              : [
                  {
                    name: 'Void Invoice',
                    value: 'void',
                    onClick: voidInvoice
                  },
                  {
                    name: 'Mark Uncollectible',
                    value: 'uncollectible',
                    onClick: markInvoiceUncollectible
                  },
                  {
                    name: 'Mark As Paid',
                    value: 'mark_as_paid',
                    onClick: markInvoiceAsPaid
                  },
                  {
                    name: 'Retry Payment',
                    value: 'retry',
                    onClick: retryInvoicePayment
                  }
                ];
          const options: OptionDropDownItem[] = conditionalOptions.filter(e => !!e);
          return (
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 8px' }} key={repeat.id}>
              <RecordBody width="200" fontWeight={'700'}>
                {createdAt}
              </RecordBody>
              <RecordBody width="20" fontWeight="800">
                {repeatTotal}
              </RecordBody>
              <RecordBody width="150" fontWeight="800" flexEnd noMargin>
                <BillingStatus blue={isBlue} green={isGreen} red={isRed} gray={!isBlue && !isGreen && !isRed} noMaxWidth={!!failReason}>
                  {failReason || (isMarkedAsPaid ? 'Marked Paid' : isUncollectible ? 'Uncollectible' : statusTitle)}
                </BillingStatus>
              </RecordBody>
              <OptionDropdown
                containerRelative
                menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
                noApplyButton
                options={[{ optionType: OPTION_DROPDOWN_TYPES.BUTTONS, id: 'options', items: options }]}
              />
            </div>
          );
        })}
      </>
    );
  };

  const renderSubscription = (subscription: Subscription) => {
    const { subscriptionNumber, subscriptionName, period, orderTotal } = getSubscriptionTitles({ subscription });
    return (
      <BookingListItem key={subscription.id}>
        <UserBookingContainer autoHeight={invoices}>
          {!invoices && (
            <>
              <RecordBody width="80" fontWeight={'800'}>
                {subscriptionNumber}
              </RecordBody>
              <RecordBody width="200" fontWeight={'600'}>
                {subscriptionName}
              </RecordBody>
              <RecordBody width="20" fontWeight="800">
                {orderTotal}
              </RecordBody>
              <RecordBody width="150" fontWeight="800" flexEnd noMargin>
                Billed {period}
              </RecordBody>
            </>
          )}
          <SubscriptionsInvoiceListContainer>{invoices && renderRepeatsList(subscription)}</SubscriptionsInvoiceListContainer>
        </UserBookingContainer>
        <BookingsDivider />
      </BookingListItem>
    );
  };

  return (
    <ListView>
      <ListHeaderContainer>
        <TabsHeader tabs={Object.values(UserSubscriptionsListTabs)} selected={currentTab} setSelected={setCurrentTab} backgroundColor={Colors.white} noPadding />
        <ListHeaderText>{active ? 'Active Memeberships' : invoices ? 'Invoices' : 'Cancelled Memeberships'}</ListHeaderText>
        <BookingsDivider />
      </ListHeaderContainer>
      <BookingsListCotainer>
        <InfiniteList
          hasMoreItems={hasMoreSubscriptions}
          fetchMore={fetchMore}
          loading={LoadingSubscriptions}
          setHasMoreItems={setHasMoreSubscriptions}
          itemRenderer={renderSubscription}
          list={appUserSubscriptions}
          offset={appUserSubscriptions?.length}
        />
      </BookingsListCotainer>
    </ListView>
  );
};

const AppUserOrders = ({ appUserId, period }: { appUserId: string; period: CurrentPeriod }) => {
  const [currentTab, setCurrentTab] = useSelectedState<typeof UserOrdersListTabs>({ defaultTab: UserOrdersListTabs.ALL, TABS_TYPES: UserOrdersListTabs });
  const active = currentTab === UserOrdersListTabs.ACTIVE;
  const [[hasMoreOrders, setHasMoreOrders], { data: { getBranchOrders: appUserOrders = [] } = {}, loading: LoadingOrders, fetchMore, refetch }] = usePaginatedQuery<ProductOrder[]>({
    query: GetBranchOrders,
    defaultStatus: Object.values(ORDER_STATUS),
    limit: 20,
    otherVariables: {
      type: 'product',
      AppUserId: [appUserId],
      createdAt_from: active ? period.start : null,
      createdAt_to: active ? period.end : null
    },
    otherParams: {
      skip: !appUserId
    }
  });

  const addOrderDrawer = useCallback((order: ProductOrder) => {
    unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
    unsetDrawerBar(DRAWER_IDS.SUBSCRIPTION_DRAWER);
    addDrawerBar({ drawerId: DRAWER_IDS.ORDER_DRAWER, recordData: order, otherData: { refetch } });
  }, []);

  return (
    <ListView>
      <ListHeaderContainer>
        <TabsHeader tabs={[UserOrdersListTabs.ALL]} selected={currentTab} setSelected={setCurrentTab} backgroundColor={Colors.white} noPadding />
        <ListHeaderText>{active ? 'Confirmed Orders' : 'All Orders'}</ListHeaderText>
        <BookingsDivider />
      </ListHeaderContainer>
      <BookingsListCotainer>
        <InfiniteList
          hasMoreItems={hasMoreOrders}
          fetchMore={fetchMore}
          loading={LoadingOrders}
          setHasMoreItems={setHasMoreOrders}
          itemRenderer={(order: ProductOrder) => {
            const { orderNumber, numberOfProducts, total, time, statusTitle, isRed, isGreen, isBlue } = getOrderTitles(order);
            return (
              <BookingListItem key={order.id}>
                <UserBookingContainer onClick={() => addOrderDrawer(order)} clickable>
                  <RecordBody width="80" fontWeight={'800'}>
                    {orderNumber}
                  </RecordBody>
                  <RecordBody width="150" fontWeight={'600'}>
                    {numberOfProducts}
                  </RecordBody>
                  <RecordBody width="150" fontWeight="400">
                    <strong>{time}</strong>
                  </RecordBody>
                  <RecordBody width="auto" fontWeight="800">
                    {total}
                  </RecordBody>
                  <RecordBody width="150" fontWeight="800" flexEnd noMargin>
                    <BillingStatus blue={isBlue} green={isGreen} red={isRed} gray={!isBlue && !isGreen && !isRed}>
                      {statusTitle}
                    </BillingStatus>
                  </RecordBody>
                </UserBookingContainer>
                <BookingsDivider />
              </BookingListItem>
            );
          }}
          list={appUserOrders}
          offset={appUserOrders?.length}
        />
      </BookingsListCotainer>
    </ListView>
  );
};

export const UserOrdersList = ({ userProfile, currentPeriod }: { userProfile: BranchAppUser; currentPeriod: CurrentPeriod }) => {
  return (
    <UserBookingsContainer>
      <AppUserOrders appUserId={userProfile?.id} period={currentPeriod} />
    </UserBookingsContainer>
  );
};

export const UserSubscriptionsList = ({ userProfile, currentPeriod }: { userProfile: BranchAppUser; currentPeriod: CurrentPeriod }) => {
  const userPetRecordsIds = [...(userProfile?.Pets || []), ...(userProfile?.ChappedPets || [])].filter(Boolean)?.map(pet => pet?.PetRecord?.id) || [];

  return (
    <UserBookingsContainer>
      <AppUserSubscriptions userPetRecordsIds={userPetRecordsIds} />
    </UserBookingsContainer>
  );
};

export const UserBookingsList = ({ userProfile, currentPeriod }: { userProfile: BranchAppUser; currentPeriod: CurrentPeriod }) => {
  const userPetRecordsIds = [...(userProfile?.Pets || []), ...(userProfile?.ChappedPets || [])].filter(Boolean)?.map(pet => pet?.PetRecord?.id) || [];

  return (
    <UserBookingsContainer>
      <AppUserBookings userPetRecordsIds={userPetRecordsIds} period={currentPeriod} />
    </UserBookingsContainer>
  );
};
