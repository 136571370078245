import React, { useMemo, useState } from 'react';
import { BillingStatsContainer, BillingStatsTitle, FilterItem, FilterItemCount, FilterItemText, SelectedBillingFiltersContainer, SelectedBillingFiltersWrapper, VerticalDivider } from './styled';
import { useQuery } from '@apollo/client';
import { GetProviderBranchesCounts } from '../../../queries';
import { Branch } from '../../../components/Profile/types';
import { toDateInput } from '../../../utils/dates';
import { BILLING_FILTERS, defaultCounts, ProviderCountsOutput } from './types';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { Divider } from '../../Pets/Health/styled';
import BranchBillingStatsFilterItems from './BranchBillingStatsFilterItems';
import { ViewContainer } from '../../../components/Shared/Shared';
import Colors from '../../../Colors';
import { getCountForSelectedFilter, getCurrentBillingFilters } from './utils';

const BranchBillingProviderStatsSection = ({
  filters,
  title,
  providerCounts,
  branches,
  options,
  setSelectedBranch,
  currentPeriod
}: {
  filters: BILLING_FILTERS[];
  title?: string;
  providerCounts: Branch['branchCounts'];
  branches: ProviderCountsOutput['getProviderBranchesCounts']['branches'];
  setSelectedBranch: (branchId: string) => void;
  options?: { renderOrderInvoicesTotal?: boolean; renderSubscriptionsTotal?: boolean; renderSubscriptionsInvoiceTotal?: boolean };
  currentPeriod: { start: string; end: string };
}) => {
  const selectedFilterState = useState(filters[0]);
  const [selectedFilter] = selectedFilterState;

  const { renderOrderInvoicesTotal = false, renderSubscriptionsTotal = false, renderSubscriptionsInvoiceTotal = false } = options || {};

  const sortedBranches = useMemo(() => {
    if (!branches) {
      return [];
    }
    return [...(branches || [])].sort((a, b) => {
      const selectedFilterCountBranchA = getCountForSelectedFilter({ counts: a.counts!, selectedFilter });
      const selectedFilterCountBranchB = getCountForSelectedFilter({ counts: b.counts!, selectedFilter });

      return selectedFilterCountBranchB - selectedFilterCountBranchA;
    });
  }, [branches, selectedFilter]);

  const billingSectionsTitles = {
    period: 'Period',
    date: `${toDateInput(currentPeriod.start)} - ${toDateInput(currentPeriod.end)}`
  };

  const invoicesSectionsTitles = {
    period: 'Issue Date',
    date: `${toDateInput(currentPeriod.end)}`
  };

  const subscriptionsSectionsTitles = {
    period: 'Period',
    date: `${toDateInput(currentPeriod.start)} - ${toDateInput(currentPeriod.end)}`
  };

  const subscriptionInvoiceSectionsTitles = {
    period: 'Pending and failed payments',
    date: `All time`
  };

  const sectionsTitles = renderSubscriptionsTotal
    ? subscriptionsSectionsTitles
    : renderOrderInvoicesTotal
    ? invoicesSectionsTitles
    : renderSubscriptionsInvoiceTotal
    ? subscriptionInvoiceSectionsTitles
    : billingSectionsTitles;

  return (
    <>
      <BillingStatsContainer noMargin>
        <BillingStatsTitle>{title}</BillingStatsTitle>
        <SelectedBillingFiltersContainer>
          <SelectedBillingFiltersWrapper noBottomRadius>
            <FilterItem minWidth={120}>
              <FilterItemText>{sectionsTitles.period}</FilterItemText>
              <FilterItemCount>{sectionsTitles.date}</FilterItemCount>
            </FilterItem>
            <VerticalDivider />
            <BranchBillingStatsFilterItems counts={providerCounts!} filters={filters} selectedFilterState={selectedFilterState} />
          </SelectedBillingFiltersWrapper>
        </SelectedBillingFiltersContainer>
        <Divider color={'#F6F6F6'} height={2} />
      </BillingStatsContainer>
      {sortedBranches.map(({ Branch, counts }, index) => {
        const isLastBranch = index === branches.length - 1;
        return (
          <BillingStatsContainer noMargin={!isLastBranch} key={Branch.id} onClick={() => setSelectedBranch(Branch.id)} clickable>
            <SelectedBillingFiltersContainer>
              <SelectedBillingFiltersWrapper small noTopRadius noBottomRadius={!isLastBranch} hoverable>
                <FilterItem marginToRight clickable minWidth={120}>
                  <FilterItemText inactiveColor={Colors.black}>{Branch.name}</FilterItemText>
                </FilterItem>
                <BranchBillingStatsFilterItems
                  counts={counts!}
                  filters={filters}
                  selectedFilterState={selectedFilterState}
                  options={{ renderActiveBar: false, renderVerticalDivider: false, renderTitles: false, small: true }}
                />
              </SelectedBillingFiltersWrapper>
            </SelectedBillingFiltersContainer>
            {!isLastBranch && <Divider color={'#F6F6F6'} />}
          </BillingStatsContainer>
        );
      })}
    </>
  );
};

const BranchBillingProviderStats = ({
  selectedBranchState,
  invoiceFilterState,
  subscriptionsFilterState,
  subscriptionInvoiceFilterState,
  currentPeriod
}: {
  selectedBranchState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
  invoiceFilterState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  subscriptionsFilterState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  subscriptionInvoiceFilterState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  currentPeriod: { start: string; end: string };
}) => {
  const { data: { getProviderBranchesCounts: { providerCounts = defaultCounts, branches = [] } = {} } = {}, loading } = useQuery<ProviderCountsOutput>(GetProviderBranchesCounts, {
    variables: {
      timestamp_from: currentPeriod.start,
      timestamp_to: currentPeriod.end
    },
    fetchPolicy: 'cache-and-network'
  });
  const { filtersWithoutInvoices, invoicesFilters, subscriptionsFilters, subscriptionInvoiceFilters } = getCurrentBillingFilters(currentPeriod);

  const [_invoiceFilter, setInvoiceFilter] = invoiceFilterState;
  const [_subscriptionsFilter, setSubscriptionsFilter] = subscriptionsFilterState;
  const [_selectedBranch, setSelectedBranch] = selectedBranchState;
  const [_subscriptionInvoiceFilter, setSubscriptionInvoiceFilter] = subscriptionInvoiceFilterState;

  const setSelectedBranchInvoice = (branchId: string) => {
    setInvoiceFilter(true);
    setSubscriptionsFilter(false);
    setSubscriptionInvoiceFilter(false);
    setSelectedBranch(branchId);
  };

  const setSelectedBranchActive = (branchId: string) => {
    setInvoiceFilter(false);
    setSubscriptionsFilter(false);
    setSubscriptionInvoiceFilter(false);
    setSelectedBranch(branchId);
  };

  const setSelectedBranchSubscription = (branchId: string) => {
    setSubscriptionsFilter(true);
    setInvoiceFilter(false);
    setSubscriptionInvoiceFilter(false);
    setSelectedBranch(branchId);
  };

  const setSelectedBranchSubscriptionInvoice = (branchId: string) => {
    setSubscriptionsFilter(false);
    setInvoiceFilter(false);
    setSubscriptionInvoiceFilter(true);
    setSelectedBranch(branchId);
  };

  return (
    <ViewContainer style={{ overflow: 'scroll' }}>
      {loading && <CenteredLoader size={50} />}
      {!loading && (
        <>
          <BranchBillingProviderStatsSection
            branches={branches}
            providerCounts={providerCounts}
            filters={filtersWithoutInvoices}
            title={'Active Billing'}
            setSelectedBranch={setSelectedBranchActive}
            currentPeriod={currentPeriod}
          />

          <BranchBillingProviderStatsSection
            branches={branches}
            providerCounts={providerCounts}
            filters={invoicesFilters}
            title={'Invoices'}
            options={{ renderOrderInvoicesTotal: true }}
            setSelectedBranch={setSelectedBranchInvoice}
            currentPeriod={currentPeriod}
          />

          <BranchBillingProviderStatsSection
            branches={branches}
            providerCounts={providerCounts}
            filters={subscriptionsFilters}
            title={'Memberships'}
            options={{ renderSubscriptionsTotal: true }}
            setSelectedBranch={setSelectedBranchSubscription}
            currentPeriod={currentPeriod}
          />

          <BranchBillingProviderStatsSection
            branches={branches}
            providerCounts={providerCounts}
            filters={subscriptionInvoiceFilters}
            title={'Pending and failed payments'}
            options={{ renderSubscriptionsInvoiceTotal: true }}
            setSelectedBranch={setSelectedBranchSubscriptionInvoice}
            currentPeriod={currentPeriod}
          />
        </>
      )}
    </ViewContainer>
  );
};

export default BranchBillingProviderStats;
