import { useReactiveVar } from '@apollo/client';
import React, { useRef } from 'react';
import { FILTERS_ID } from '../../../components/Shared/Filters/config';
import { ViewContainer } from '../../../components/Shared/Shared';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBillingListBranchAppointments } from '../../../queries';
import { vars } from '../../../reactive';
import BookingsList, { BookingsBulkActionsRef } from '../../Bookings/BookingsList';
import { BookingActionsContainer } from '../../Bookings/styled';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking } from '../../Bookings/types';
import { ORDER_STATUS } from '../../Orders/types';
import { BRANCH_BILLING_ALL_TIME_FILTERS, CurrentPeriod } from './types';

const BranchBillingBookings = ({ period, selectedFilter }: { period: CurrentPeriod; selectedFilter: string }) => {
  const isPayment = selectedFilter === BRANCH_BILLING_ALL_TIME_FILTERS.BOOKINGS_PAYMENT_UNBILLED_ALL;
  const paginatedQueryResult = usePaginatedQuery<Booking[]>({
    query: GetBillingListBranchAppointments,
    defaultStatus: [BOOKING_STATUS_TYPES.CONFIRMED],
    limit: 50,
    otherVariables: {
      ...(isPayment
        ? {
            order_status: [ORDER_STATUS.PAYMENT_REJECTED, ORDER_STATUS.PAYMENT_PENDING]
          }
        : {
            billed_on_from: period.start,
            billed_on_to: period.end,
            timestamp_from: period.start,
            timestamp_to: period.end
          }),
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      requisite_queries: [],
      alternative_queries: []
    }
  });

  const headerActions = useReactiveVar(vars.bookingsHeaderActions);
  const filterHeaderActions = headerActions.filter(({ id }) => id !== FILTERS_ID);

  const bookingsListRef = useRef<BookingsBulkActionsRef>(null);

  return (
    <ViewContainer>
      {filterHeaderActions.map(({ id, action }) => (
        <BookingActionsContainer key={id}>{action}</BookingActionsContainer>
      ))}
      <BookingsList paginatedQueryResult={paginatedQueryResult} reduceAppointments showTotal showPetTags ref={bookingsListRef} showFailReason={isPayment} />
    </ViewContainer>
  );
};

export default BranchBillingBookings;
