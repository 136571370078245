import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Branch } from '../../../components/Profile/types';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { GetBranchCounts } from '../../../queries';
import { toDateInput } from '../../../utils/dates';
import { RecordsContainer } from '../../styled';
import BranchAppUsers from './BranchBillingAppUsers';
import BranchBillingBookings from './BranchBillingBookings';
import BranchBillingInvoices from './BranchBillingInvoices';
import BranchBillingOrders from './BranchBillingOrders';
import BranchBillingStatsFilterItems from './BranchBillingStatsFilterItems';
import BranchBillingSubscriptionRepeats from './BranchBillingSubscriptionRepeats';
import BranchBillingSubscriptions from './BranchBillingSubscriptions';
import { BillingStatsContainer, FilterItem, FilterItemCount, FilterItemText, SelectedBillingFiltersContainer, SelectedBillingFiltersWrapper, VerticalDivider } from './styled';
import { BRANCH_BILLING_ALL_TIME_FILTERS, BRANCH_BILLING_FILTERS, BRANCH_BILLING_INVOICES_FILTERS, BRANCH_BILLING_SUBSCRIPTIONS_FILTERS, defaultCounts } from './types';
import { getCurrentBillingFilters } from './utils';

const BranchBillingBranchStats = ({
  currentPeriod,
  invoiceFilter = false,
  subscriptionsFilter = false,
  subscriptionInvoiceFilter = false
}: {
  currentPeriod: { start: string; end: string };
  invoiceFilter?: boolean;
  subscriptionsFilter?: boolean;
  subscriptionInvoiceFilter?: boolean;
}) => {
  const { filtersWithoutInvoices, invoicesFilters, subscriptionsFilters, subscriptionInvoiceFilters } = getCurrentBillingFilters(currentPeriod);

  const { data: { getBranchCounts: branchCounts = defaultCounts } = {}, loading } = useQuery<{
    getBranchCounts: Branch['branchCounts'];
  }>(GetBranchCounts, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      timestamp_from: currentPeriod.start,
      timestamp_to: currentPeriod.end
    }
  });

  const billingSectionsTitles = {
    filters: filtersWithoutInvoices,
    period: 'Period',
    date: `${toDateInput(currentPeriod.start)} - ${toDateInput(currentPeriod.end)}`
  };

  const invoicesSectionsTitles = {
    filters: invoicesFilters,
    period: 'Issue Date',
    date: `${toDateInput(currentPeriod.end)}`
  };

  const subscriptionsSectionsTitles = {
    filters: subscriptionsFilters,
    period: 'Period',
    date: `${toDateInput(currentPeriod.start)} - ${toDateInput(currentPeriod.end)}`
  };

  const subscriptionInvoiceSectionsTitles = {
    filters: subscriptionInvoiceFilters,
    period: 'Pending and failed payments',
    date: `All time`
  };

  const billingSections = invoiceFilter
    ? invoicesSectionsTitles
    : subscriptionsFilter
    ? subscriptionsSectionsTitles
    : subscriptionInvoiceFilter
    ? subscriptionInvoiceSectionsTitles
    : billingSectionsTitles;

  const filters = billingSections.filters;

  const selectedFilterState = useState(filters[0]);
  const [selectedFilter] = selectedFilterState;

  return (
    <>
      <BillingStatsContainer>
        <SelectedBillingFiltersContainer>
          <SelectedBillingFiltersWrapper>
            <FilterItem>
              <FilterItemText>{billingSections.period}</FilterItemText>
              <FilterItemCount>{billingSections.date}</FilterItemCount>
            </FilterItem>
            <VerticalDivider />
            <BranchBillingStatsFilterItems counts={branchCounts} filters={filters} selectedFilterState={selectedFilterState} loading={loading} />
          </SelectedBillingFiltersWrapper>
        </SelectedBillingFiltersContainer>
      </BillingStatsContainer>
      <RecordsContainer>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            {selectedFilter === BRANCH_BILLING_FILTERS.APP_USERS && <BranchAppUsers period={currentPeriod} />}
            {selectedFilter === BRANCH_BILLING_FILTERS.BOOKINGS && <BranchBillingBookings period={currentPeriod} />}
            {Object.values(BRANCH_BILLING_SUBSCRIPTIONS_FILTERS).includes(selectedFilter as BRANCH_BILLING_SUBSCRIPTIONS_FILTERS) && (
              <BranchBillingSubscriptions period={currentPeriod} filter={selectedFilter as BRANCH_BILLING_SUBSCRIPTIONS_FILTERS} />
            )}
            {selectedFilter === BRANCH_BILLING_FILTERS.ORDERS && <BranchBillingOrders period={currentPeriod} />}
            {Object.values(BRANCH_BILLING_INVOICES_FILTERS).includes(selectedFilter as BRANCH_BILLING_INVOICES_FILTERS) && (
              <BranchBillingInvoices period={currentPeriod} filter={selectedFilter as BRANCH_BILLING_INVOICES_FILTERS} />
            )}
            {selectedFilter === BRANCH_BILLING_ALL_TIME_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL && (
              <BranchBillingSubscriptionRepeats period={currentPeriod} filter={selectedFilter as BRANCH_BILLING_ALL_TIME_FILTERS} />
            )}
            {selectedFilter === BRANCH_BILLING_ALL_TIME_FILTERS.BOOKINGS_PAYMENT_UNBILLED_ALL && (
              <BranchBillingBookings period={currentPeriod} selectedFilter={selectedFilter as BRANCH_BILLING_ALL_TIME_FILTERS} />
            )}
          </>
        )}
      </RecordsContainer>
    </>
  );
};

export default BranchBillingBranchStats;
